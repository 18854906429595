
.AvatarContainer {
    display: flex;
    margin-bottom: 14px;
}

.AvatarContainer > * {
    margin: 16px;
    margin-right: 0;
}

.AvatarLabel {
    margin:4px;
    margin-top:6px;   
}

.AvatarLabel > button {
    text-transform: none;
}

